.cookie-policy {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;

  h1,
  h2,
  h3 {
    color: #333;
  }

  p,
  ul {
    color: #555;
    line-height: 1.6;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
