@import "../../styles/variables.scss";

.coming-soon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f8f8;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;

  .content {
    max-width: 600px;
    width: 100%;

    .logo {
      max-width: 300px;
      height: auto;
      margin-bottom: 20px;
    }

    h1 {
      font-size: 2.5rem;
      color: #333;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2rem;
      color: #666;
      margin-bottom: 20px;
    }

    .social-links {
      margin-top: 20px;

      .social-link {
        display: inline-block;
        margin: 0 10px;
        text-decoration: none;
        color: $color-primary;
        font-weight: bold;
        transition: transform 0.3s ease;
        width: 50px;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .contact-info {
      font-size: 1rem;
      color: #333;

      p {
        margin: 10px 0;

        a {
          color: #007bff;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
