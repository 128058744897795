@import "./variables";

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  // background-color: $color-primary;
  // color: $color-secondary;
  // font-family: $font-primary;
  background-color: $bg-color;
  color: $color-secondary;
  font-family: $font-primary;
  height: 100%;
}

.container-default {
  padding: 20px;
  height: 100%;
}

.header,
.services,
.about,
.testimonials,
.contact {
  padding: 50px 20px;
  text-align: center;
  scroll-margin-top: 120px;

  @media (max-width: 768px) {
    scroll-margin-top: 50px;
  }
}

.header {
  // background-color: $color-secondary;
  color: $font-color;
}

.services {
  // background-color: $color-highlight;
  color: $font-color;
}

.about {
  // background-color: $color-accent;
  color: $color-secondary;
}

.contact {
  // background-color: #fff;
  color: $color-secondary;
}

/* Media queries */
@media (max-width: 768px) {
  .header,
  .services,
  .about,
  .testimonials,
  .contact {
    padding: 30px 10px;
  }

  .container {
    padding: 10px;
  }
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: $bg-color;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: $color-primary;
  border-radius: 10px;
  border: 3px solid $bg-color;
}

body::-webkit-scrollbar-thumb:hover {
  background: $color-highlight;
}
