$color-primary: #78b5ad;
$color-secondary: #005075;
$color-accent: #d8c19c;
$color-highlight: #3aa89c;
$color-secondary-highlight: #4684a0;

$font-primary: "Poppins", sans-serif;
$font-color: #000000;
$font-secondary-color: #ffffff;

$bg-color: #f5ffff;
